.App {
  text-align: center;
  overflow-x: hidden !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.page-link {
  background-color: 	rgb(211,211,211);
  color: black!important;
  border:none;
}
.page-link:focus{
  box-shadow: none !important;
}
.page-link:hover{
  background: grey !important;
  background-color: grey;
}
.page-link:target-within{
  background: grey !important;
  background-color: grey;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-style-link{
  text-decoration: none;
  color: initial;
}

.errorSpan{
  color:red;
  font-size: 12px;
}

.form_dc{
  display: contents;
}

/* React Loading Overlay */
._loading_overlay_overlay {
  z-index: 1000000 !important;
}

/* Hide size per page in bootstrap table */
.react-bs-table-sizePerPage-dropdown {
  visibility: hidden !important;
}

.nmp{
  margin: 0;
  padding: 0;
}

.hiddenClass{
  display: none !important;
}

/* Sort Caret for bootstrap table */
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.loading-indicator:before {
  animation: 0.5s ease-in 0s 1 fadeIn;
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading...';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.5rem;        
}

.btn:disabled:hover,
select:disabled:hover,
label:disabled:hover {
  cursor: not-allowed !important;
  z-index: 2000;  
}

select:enabled:hover {
  cursor:  pointer !important;
  z-index: 2000;
}

.horizontal {
  overflow: auto;
  white-space: nowrap;
}
.table {
  overflow: auto;
  table-layout: auto !important;
} 
.card-body {
  padding: 10px !important;
  display:grid;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.search-label{
  margin-bottom:0px !important;
}

.search-box .search {
  width: 100%;
  position: relative;
  display: flex;
}
.search-box .searchTerm {
  width: 100%;
  border: 3px solid #00B4CC;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
}
.search-box .searchTerm:focus{
  color: #00B4CC;
}
.btn-success{
  background-color: rgba(168, 196, 11);
  color: rgba(255, 255, 255, 0.9);
  border : 1px solid rgba(168, 196, 11);
}

.btn-success:hover{
  background-color: rgb(171, 194, 41);
  border-color: rgb(171, 194, 41);
}

.btn-success:focus{
  background-color: rgb(171, 194, 41);
  border-color: rgb(171, 194, 41);
}
.btn-success2{
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  background: rgb(235,37,47);
  background: linear-gradient(79deg, rgba(235,37,47,1), rgba(58,2,5,1));
  border : 1px solid rgb(235,37,47);
}
.btn-success2:hover{
  background-color: #d1d1d1;
  border-color: rgb(235,37,47);
}
.btn-success2:focus{
  background: rgb(133, 129, 129);
  background: linear-gradient(79deg, rgba(235,37,47,1), rgba(58,2,5,1));
  border-color: rgb(235,37,47);
}

.search-box .searchButton {
  width: 40px;
  height: 50px;
  border: 1px solid #00B4CC;
  background: #00B4CC;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.DraftEditor-editorContainer{
  max-height: 300px;
  overflow: scroll;
}

.handOnHover:hover{
  cursor: pointer;
}

.arrowOnHover:hover{
  cursor: default;
}

.h100{
  height:100%
}
.crossSell{
  display : none!important;
}

*:disabled:hover{
  cursor: not-allowed;
}

.disableHover:hover{
  cursor: not-allowed;
}

body.modal-open .background-container{
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}

.react-bootstrap-table-page-btns-ul{
  display: flex;
  justify-content: end;
  margin-top: 1rem;
}

.custom-ul{
  list-style-type: initial !important;
}

.custom-ol{
  list-style-type: decimal !important;
}

.custom-RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.custom-code-block{
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.btn.focus, .btn:focus {
    border: 0px solid;
    box-shadow: none;
}

.MuiBox-root-2 {
  width: 100%;

  display: flex;

  justify-content: center;
}

.MuiBox-root img{
  width: 70% ;
  height: 50% ;
  margin-top: 20px;
  margin-bottom: 20px;

}

.MuiButton-containedPrimary
{
  background-color:rgba(168, 196, 11, .8) !important;
}

.MuiDialogContent-dividers .MuiTypography-subtitle1 {
  display: none;
}


.MuiDialog-paperWidthSm {
  max-width: 600px !important;
  
  height: 400px;
 
}

.MuiDialogActions-root {
  content: "Powered by";
  color: #282c34;
  background-image: url(assets/images/payupowered.png);
  background-repeat: no-repeat;
  display: inline-flex;
  font-size: x-small !important;
  background-size: 100px;
  margin-top: 7px;
  margin-left:5px;
  margin-bottom: -1px !important;
  background-position:  absolute;
  
}

.MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
}

.MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  max-height: max-content !important;
  /* height: 50%; */
  padding: 16px 178px ;
  display: flex;
  justify-content: center;
}

.MuiTypography-h6 {
  font-size: 1.55rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 545 !important;
  line-height: 2. !important;
  letter-spacing: 0.0075em;
 
  height: 70% !important;
}

.MuiTypography-root {
  margin: 5px;
}

.MuiDialogContent-dividers .jss1{
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiDialogActions-root {
  content: "Powered by";
  color: #282c34;
  background-image: url(assets/images/payupowered.png);
  background-repeat: no-repeat;
  display: inline-flex;
  font-size: x-small !important;
  background-size: 90px;
  background-position: left;
}

.verticalAlignCenter table tbody tr td{
  vertical-align:middle;
}

.verticalAlignCenter table thead tr th{
  vertical-align:middle;
}

.table td, .table th {
  vertical-align: middle;
}

@media only screen and (max-width: 480px) {


.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  background-color:rgba(168, 196, 11, .8) !important;
  color: #fff !important;
  border: none;
  font-size: 12px;
  padding: 9px 6px;
  
}
.react-bootstrap-table-page-btns-ul {
  display: flex;
  justify-content: end;
  margin-top: -2rem;
}

}
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: rgba(168,196,11,.8);
  box-shadow: 30px 0 0 rgba(168,196,11,.8);
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: rgba(168,196,11,.8);
  box-shadow: 30px 0 0 rgba(168,196,11,.8);
}

@keyframes rotate {
  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 rgba(168,196,11,.8);
  }
  50% {
    box-shadow: 0 0 0 rgba(168,196,11,.8);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 rgba(168,196,11,.8);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 rgba(168,196,11,.8);
  }
  50% {
    box-shadow: 0 0 0 rgba(168,196,11,.8);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 rgba(168,196,11,.8);
    margin-top: 0;
  }
}
